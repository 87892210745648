import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../utils/auth.service';


@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(private injector: Injector, public auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.auth.getToken() ? this.auth.getToken() : '';
    const settingsToken = localStorage.getItem('settings_token') ? localStorage.getItem('settings_token') : '';
    request = request.clone({
      setHeaders: {
        Authorization: authToken,
        'company-token' : settingsToken
      }
    });
    return next.handle(request);
  }
}

