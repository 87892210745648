import { Component } from '@angular/core';
import {DataService} from './utils/data.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Order';

  constructor(private http: DataService, private titleService: Title) {
    if (localStorage.getItem('theme')) {
      document.querySelector('body').style.cssText = '--theme-color: ' + localStorage.getItem('theme');
    }
    if (localStorage.getItem('title')) {
      this.titleService.setTitle(localStorage.getItem('title'));
    }
    this.getSettings().then();
  }


  async getSettings(): Promise<any> {
    const res = await this.http.get('', {}, 'web/settings', 'auth');
    try {
      localStorage.setItem('settings_token', res.token);
      localStorage.setItem('theme', res.theme);
      localStorage.setItem('logo', res.logo);
      localStorage.setItem('login_img', res.login_img);
      localStorage.setItem('about_link', res.about_link);
      if (res.title) {
        localStorage.setItem('title', res.title);
        this.title = res.title;
        this.titleService.setTitle(this.title);
      }
      if (res.theme) {
        document.querySelector('body').style.cssText = '--theme-color: ' + res.theme;
      }
    } catch (e) {
      console.log(e);
    }
  }

}
