// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleApiKey: 'AIzaSyAZrZg5utTPbOcTkveRd2Z7hEQdOcqjgfY',
  // AIzaSyAZrZg5utTPbOcTkveRd2Z7hEQdOcqjgfY
  hereApiKey: '3Xz_d2T_zyHKm1WhBNRAdtmToAQ_HhLmWtMstKeFo34',
  auth: 'https://synco-api.roadcast.co.in/api/v1/auth/',
  order: 'https://synco-api.roadcast.co.in/api/v1/order/',
  firebaseConfig: {
    apiKey: 'AIzaSyCTks4mDMdTsdtZ2duLTfBCLvFvz7_J_NA',
    authDomain: 'synco-dc96f.firebaseapp.com',
    databaseURL: 'https://synco-dc96f.firebaseio.com',
    projectId: 'synco-dc96f',
    storageBucket: 'synco-dc96f.appspot.com',
    messagingSenderId: '396410044695',
    appId: '1:396410044695:web:b349e9b39770b377',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
