import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthService} from './utils/auth.service';
import {LayoutModule} from '@angular/cdk/layout';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DataService} from './utils/data.service';
import {Interceptor} from './config/http.interceptor';
import {AgmCoreModule} from '@agm/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {environment} from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      language: 'en',
      libraries: ['geometry', 'places'],

    }),
    MatProgressBarModule,
  ],
  providers: [
    AuthService,
    DataService,

    {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true},
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
