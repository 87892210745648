import {Injectable, OnDestroy} from '@angular/core';
import {DataService} from './data.service';
import {Subject, Subscription} from 'rxjs';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MapsAPILoader} from '@agm/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  profile: any = {
    addresses : [],
    country_code: null,
    device_token: null,
    device_type: null,
    id: null,
    email: '',
    is_active: null,
    mobile_number: '',
    name: '',
    picture: null,
    web_token: null
  };
  outlet: any = {
    latitude: 0,
    longitude: 0,
    outlet_address: '',
    outlet_id: null,
    outlet_name: '',
    outlet_phone: '',
    zone_id: ''
  };
  selectedAddress: any;


  public outlet$: Subject<any> = new Subject() as Subject<any>;
  public coords$: Subject<any> = new Subject() as Subject<any>;


  currentOrSelectedCoords = {};
  browserCoords: any;

  constructor(public http: DataService,
              private mapsAPILoader: MapsAPILoader) {
    this.loadProfile().then();

    this.coords$.subscribe( coords => {
      this.currentOrSelectedCoords = coords;
      this.loadOutlet().then();
    });
    this.mapsAPILoader.load().then();
  }

  getLocation(): void {
    if (this.selectedAddress) {

      this.coords$.next( this.selectedAddress.geom );

    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.coords$.next( { latitude : position.coords.latitude, longitude: position.coords.longitude } );
        this.browserCoords = { latitude : position.coords.latitude, longitude: position.coords.longitude };
        this.calculateAddressDistance();
      }, () => {
        if (this.profile.addresses.length) {
          this.coords$.next( {
            latitude : this.profile.addresses[0].geom.latitude,
            longitude: this.profile.addresses[0].geom.longitude
          } );
        }
      });
    } else {
      if (this.profile.addresses.length) {
        this.coords$.next( {
          latitude : this.profile.addresses[0].geom.latitude,
          longitude: this.profile.addresses[0].geom.longitude
        } );
      }
    }
  }

  async loadProfile(): Promise<any> {
    try {
      this.profile = (await this.http.get('', {}, 'web/customer', 'auth')).data[0];
      if (!this.profile.picture) {
        this.profile.picture = '/assets/img/user.svg';
      }
      this.getLocation();
      this.calculateAddressDistance();
    } catch (e) {
      console.log(e);
    }
  }


  async loadOutlet(): Promise<any> {
    try {
      if (this.currentOrSelectedCoords.hasOwnProperty('latitude')) {
        this.outlet = (await this.http.create('', this.currentOrSelectedCoords, 'web/outlets', 'auth')).data;
        this.outlet$.next(this.outlet);
      } else {
        this.getLocation();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getProfile(): Promise<any> {
    if (this.profile.id) {
      return this.profile;
    } else {
      await this.loadProfile();
      return this.profile;
    }
  }

  setAddress(address): void {
    this.selectedAddress = address;
    this.getLocation();
  }
  getSelectedAddress(): any {
    return this.selectedAddress;
  }

  async getOutlet(): Promise<any> {
    if (this.outlet.id) {
      return this.outlet;
    } else {
      await this.loadOutlet();
      return this.outlet;
    }
  }

  calculateAddressDistance(): void {
    if (this.browserCoords) {
      this.profile.addresses.forEach((address, index) => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(this.browserCoords.latitude, this.browserCoords.longitude),
          new google.maps.LatLng(address.geom.latitude, address.geom.longitude));
        this.profile.addresses[index].distance = distance > 1000 ? (distance / 1000 * 1.3).toFixed(2) + ' KM' : distance.toFixed(2) + ' M';
        this.profile.addresses[index].nearby = distance < 100;
      });
    }
  }



}
