import {ChangeDetectorRef, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DataService} from './data.service';
import {ProfileService} from './profile.service';
@Injectable()
export class AuthService {
  constructor(private router: Router, private http: DataService, private profileService: ProfileService) { }
  private token: string;

  public getToken(): string {
    return  this.token;
  }

  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    if (this.token && this.token !== '') {
      return true;
    } else {
      this.token = localStorage.getItem('auth_token');
      if (this.token && this.token !== '') {
        return true;
      } else {
        return false;
      }
    }

  } // isAuthenticated()


  public saveTokenToLocalStorage(token: string): void {
    this.token = token;
    localStorage.setItem('auth_token', this.token);
    this.profileService.loadProfile();
    this.router.navigateByUrl('app/home');
  }

  public logout(): void {
    try {
      this.http.get('', {}, 'web/logout', 'auth');
    } catch (e) {

    }
    delete this.token;
    localStorage.removeItem('auth_token');
    this.router.navigate(['auth/login']);
  }
}
